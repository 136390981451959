import { post, App_ID } from "./publicHeader";

class Generalize {
  //
  async invite_code() {
    // 个人推广信息
    try {
      const res = await post("user/user.Invite/invite_code", {
        app_id: App_ID
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  async Withdrawal(money, account, type) {
    // 请求提现
    try {
      const res = await post("user/user.Invite/Withdrawal", {
        app_id: App_ID,
        money,
        account,
        type
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  async inviteList() {
    // 推广列表
    try {
      const res = await post("/user/user.Invite/invite_list", {
        app_id: App_ID
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 提现记录
  async WithdrawalList(status, page) {
    try {
      const res = await post("/user/user.Invite/Withdrawal_list", {
        app_id: App_ID,
        status,
        page
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
}
export default new Generalize();
