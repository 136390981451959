<template>
  <!-- 活动中心 -->
  <div class="activityCont">
    <div class="nav">
      <nav-bar title="提现纪录" left-text left-arrow @click-left="lastStep" />
    </div>
    <tabs v-model="active" @change="change">
      <template v-for="(item, index) in tabData">
        <tab :title="item" :key="index">
          <div class="convert-record">
            <list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
              <template v-if="active==0">
                <cell
                  v-for="(val, idx) in activityData"
                  :key="idx"
                  :title="`提现金额：${val.money}`"
                  :label="`提现账号: ${val.account}`"
                  :value="`提现时间: ${val.create_time}`"
                />
              </template>
            </list>
          </div>
        </tab>
      </template>
    </tabs>
  </div>
</template>

<script>
import Generalize from "@/api/generalize";
import { navBar, List, Cell, Tab, Tabs } from "vant";

export default {
  components: {
    navBar,
    List,
    Cell,
    Tab,
    Tabs
  },
  data() {
    return {
      active: 0,
      show: false,
      activityData: [],
      page: 0,
      list: [],
      loading: false,
      finished: false,
      loadingTwo: false,
      finishedTwo: false,
      details: {},
      tabData: ["申请中", "提现成功", "提现失败"]
    };
  },
  mounted() {
    // this.getRecord();
  },
  methods: {
    goDetail(item) {
      this.$router.push({
        path: "/exchange_detail",
        query: {
          id: item.id,
          type: 1 //0是积分商城 1是中奖兑换
        }
      });
    },
    change() {
      this.page = 0;
      this.activityData = [];
      this.getRecord();
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        this.getRecord();

        // 加载状态结束
        // this.loading = false;

        // 数据全部加载完成
        // if (this.list.length >= 40) {
        //   this.finished = true;
        // }
      }, 1000);
    },

    lastStep() {
      this.$router.go(-1);
    },
    setTime(fmt) {
      const time = new Date(fmt * 1000);
      const Y = time.getFullYear();
      const M = (time.getMonth() + 1).toString().padStart(2, "0");
      const D = time
        .getDate()
        .toString()
        .padStart(2, "0");
      const h = time
        .getHours()
        .toString()
        .padStart(2, "0");
      const m = time
        .getMinutes()
        .toString()
        .padStart(2, "0");
      const s = time
        .getSeconds()
        .toString()
        .padStart(2, "0");
      return `${Y}-${M}-${D} ${h}:${m}:${s}`;
    },
    async getRecord() {
      this.page++;
      try {
        const res = await Generalize.WithdrawalList(this.active,this.page);
        console.log(res);
        if (res.result.length > 0) {
          for (let i = 0; i < res.result.length; i++) {
            let el = res.result[i];
            this.activityData.push(el);
          }
          this.loading = false;
          console.log(this.activityData);
        } else {
          this.finished = true;
        }

        // this.wenzhangData = res.data.result
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.activityCont {
  width: 100%;
  min-height: 667px;
  position: absolute;
  top: 0;
  bottom: 0;
  background: #e6e6e6;
  overflow: hidden;
  .convert-record {
    height: calc(667px - 90px);
    overflow-x: hidden;
  }
  .shopping-item {
    width: 94%;
    height: auto;
    display: flex;
    padding: 8px 10px;
    border-radius: 4px;
    background: #ffffff;
    flex-direction: column;
    margin: 16px auto;

    .shopping-item-top {
      width: 100%;
      height: 36.5px;
      display: flex;
      color: #333333;
      justify-content: space-between;
      align-items: center;

      .shopping-item-top-left {
        width: 86%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        span {
          margin-left: 6px;
        }
      }

      .shopping-item-top-type {
        font-size: 14px;
        color: #ff8000;
      }
    }

    .shopping-item-main {
      width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .shopping-item-main-img {
        width: 84px;
        height: 84px;
        margin-left: 6px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 6px;
        }
      }

      .shopping-item-main-right {
        width: 260px;
        height: 100%;
        display: flex;
        padding: 10px;
        flex-direction: column;
        justify-content: space-between;

        .shopping-item-name {
          width: 100%;
          min-height: 30px;
          display: flex;
          /*align-items: center;*/
          justify-content: space-between;
          font-size: 14px;

          .shopping-item-name-right {
            display: flex;
            text-align: right;
            flex-direction: column;
            justify-content: flex-end;
            span {
              margin-bottom: 10px;
            }
          }
        }

        .shopping-item-main-right-price {
          flex: 1;
          font-size: 14px;
          align-items: center;
          display: flex;
          color: #ff8000;
          justify-content: space-between;
        }
      }
    }

    .shopping-price {
      width: 100%;
      line-height: 20px;
      text-align: right;
      padding-bottom: 5px;
    }

    .bottom-btn {
      width: 100%;
      height: auto;
      display: flex;
      margin-left: 5px;
      align-items: center;
      justify-content: flex-end;

      div {
        width: 70px;
        height: 24px;
        text-align: center;
        line-height: 24px;
        color: #ff8000;
        border: 1px solid #ff8000;
        border-radius: 12px;
        margin-left: 10px;
      }

      .out-order {
        color: #7c7c7c;
        border: 1px solid rgba(191, 190, 190, 1);
        margin-left: 10px;
      }
    }
  }

  .winImg {
    display: flex;
    justify-content: center;
    margin: 15px 0;

    img {
      width: 100px;
      height: 100px;
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .btn {
    display: flex;
    justify-content: space-around;
  }

  .block {
    width: 300px;
    height: 450px;
    background-color: #fff;
  }

  .nav {
    border-bottom: 1px solid #e0e0e0;
  }

  .cont {
    margin-top: 10px;
    padding: 0 14px;

    .img {
      img {
        width: 100%;
      }
    }

    .time {
      padding: 0 6px;
      margin-top: 5px;
      font-size: 12px;
      color: #666666;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .text {
      margin-top: 10px;
      font-size: 14px;
      text-indent: 25px;
      line-height: 20px;
      /*  文本*/
    }
  }
}
</style>

<style lang="scss">
.activityCont {
  .van-nav-bar .van-icon {
    color: #000;
  }

  [class*="van-hairline"]::after {
    border: 0;
  }
}
</style>
